#invoice_qr_code {
  margin: 20px auto;
  padding: 0 20px;
  width: 500px;
  max-width: 100%;
  height: auto;
  text-align: center;

  canvas {
    max-width: 100% !important;
    height: auto !important;
  }
}

#status {
  text-align: center;
}

a, button {
  display: inline-block;
  background: black;
  color: white;
  padding: 8px 15px;
  border-radius: 4px;
  margin-top: 10px;
  text-decoration: none;
  border: 0;
  font-size: 1em;
}
