#coffee_cup {
	width: 100%;
	height: auto;
}
.st0 {
	fill: #C5AD6C;
}

.st1 {
	fill: #1B1B1B;
}

.st2 {
	fill: #020203;
}

.st3 {
	fill: #544842;
}

.st4 {
	fill: #D18E4A;
}

.st5 {
	fill: #998F84;
}

.st6 {
	fill: #668E5E;
}

.st7 {
	fill: #34342F;
}

.st8 {
	fill: #8C4044;
}

.st9 {
	fill: #C1292E;
}

.st10 {
	fill: #7D5D71;
}

.st11 {
	fill: #998675;
}

.st12 {
	fill: #746458;
}

.st13 {
	fill: #EA90A4;
}

.st14 {
	fill: #486D81;
}

.st15 {
	fill: #B98252;
}
